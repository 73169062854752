<template>
	<div class="full-height size-px-14 position-relative">
		<div class="header">
			<div
				class="top top_mafi "
			><!--trans-->
				<div class="util shadow">
					<button
						class="arrow_white"
						@click="goBack"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit ml-15">{{ program.title }} </h2>
				<div
					class="util size-px-16"
					style="margin-left: auto;"
					@click="postSave"
				>
					<span
						v-show="mode == 'empty' && upload_files.length > 0"
						class="color-white"
					>{{ $language.common.complete }} <!-- 완료 --></span>
				</div>
			</div>
		</div>
		<div
			class="full-height"
		>
			<div
				v-show="mode == 'empty' && upload_files.length > 0"
				class="bg-white full-height position-relative pt-50"
				style="z-index: 0"
			>
				<div
					class="radius-20 overflow-hidden flex-column justify-center"
					style="max-height: 400px; padding: 30px 20px 20px 20px"
				>
					<div class="justify-space-between gap-10">
						<div class="flex-2 position-relative flex-column justify-center radius-20" style=" min-height: 250px; background-color: black">
							<video
								v-if="upload_files[0]"
								width="fit-content"
								autoplay
								loop
								class=""
								style="margin: 0 auto; max-width: 100%;"
								playsinline
								@loadedmetadata="onLoadMetadata"
								ref="video"
							>
								<source
									:src="upload_files[0].src"
								>
							</video>
							<div
								class="position-absolute-full flex-column justify-center items-center"
								@click="pausePlay"
							>
								<v-icon
									v-if="upload_files[0] && upload_files[0].paused"
									class="color-white" large >mdi-play-circle-outline</v-icon>
								<v-icon
									v-else-if="upload_files[0] && !upload_files[0].paused"
									class="color-white" large >mdi-pause-circle-outline</v-icon>
							</div>
						</div>
						<div
							class="flex-1 flex-column justify-end radius-20 text-center"
						>
							<div class="box radius-20 flex-column justify-center" style="min-height: 125px; background-color: black;">
								<img :src="post_file_list[0].post_file_url" class="width-100 "/>
							</div>
						</div>
					</div>
				</div>

				<div
					class="prl-20 "
				>
					<h6 class="size-px-16 font-weight-500 color-333">{{ $language.tv.title_tv_title}} <!-- 제목 --> <span class="color-red">*</span></h6>
					<input
						v-model="post_title"
						class="input-box mt-10"
						:placeholder="$language.tv.txt_tv_title"
					/>
				</div>

				<div
					class="prl-20 mt-20"
				>
					<h6 class="size-px-16 font-weight-500 color-333">{{ $language.tv.title_tv_contents}} <!-- 내용 --> <span class="color-red">*</span></h6>
					<textarea
						v-model="post_contents"
						class="width-100 pa-20 radius-10 mt-15 overflow-y-auto"
						rows="5"
						:placeholder="$language.tv.txt_tv_contents"
						maxlength="300"
					></textarea>
				</div>

				<hr class="bg-gray-light pa-5 mt-20">
				<div class="mt-10">
					<div class=" justify-space-between-in size-px-16">
						<div class="pa-13-20" @click="toEdit">{{ $language.shorts.title_shorts_cover}} <button ><img :src="require('@/assets/image/icon_arrow_right_gray.svg')" /></button></div>
						<div class="pa-13-20">{{ $language.shorts.title_shorts_option}}  <button ><img :src="require('@/assets/image/icon_arrow_right_gray.svg')" /></button></div>
					</div>
				</div>
			</div>

			<div
				v-show="upload_files.length > 0"
				class="flex-column position-absolute-full bg-white pt-50"
				:style="mode == 'edit' ? 'z-index: 1' : 'z-index: -1'"
			>
				<div
					class="full-height flex-column justify-center position-absolute-full"
					:style="is_cover ? 'z-index: 1' : 'z-index: -1'"
				>
					<div
						class="full-height flex-column justify-center overflow-y-auto ptb-50"
					>
						<div class="position-relative color-white">
							<div class="position-absolute justify-space-between width-100 pa-10 items-center" style="top: 0; left: 0; z-index: 999; background: rgba(0, 0, 0, 0.6);">
								커버 이미지
								<button
									class="color-white"
									@click="is_cover = false"
								><v-icon class="color-white">mdi-close-circle</v-icon></button>
							</div>
							<canvas
								id="canvas"
								style="width: 100%; margin-top: 4px"
								class="position-relative"
							></canvas>

							<div
								v-show="false"
							>
								<img :src="post_file_list.post_file_url" class="width-100"/>
							</div>
						</div>
					</div>
					<div
						class="mt-auto btn_wrap "
					>
						<button
							@click="save"
							class="btn btn_fill_blue"
						>커버 이미지 적용</button>
					</div>
				</div>

				<div
					v-show="!is_cover"
					class="full-height flex-column justify-center position-absolute-full "
				>
					<div
						class="full-height flex-column justify-center overflow-y-auto ptb-50"
					>
						<video
							v-if="files[0]"
							width="100%"
							controls
							class=""
							id="video"
							playsinline
						>
							<source
								:src="files[0].src"
							>
						</video>
					</div>
					<div
						class="mt-auto btn_wrap "
					>
						<button
							@click="capture"
							class="btn btn_fill_blue"
						>화면 캡처</button>
					</div>
				</div>
			</div>

			<Empty
				v-if="mode == 'empty' && upload_files.length < 1"
				class="full-height"
			>
				<template
					v-slot:body
				>
					<div
						class="text-center character2 pt-140 size-px-14 color-333"
					>
						<div>{{ $language.tv.subject_tv_upload }} <!-- 1분 이내의 동영상을 업로드 하고 게시하세요 --></div>
						<div class="mt-20">
							<button
								class="btn_l btn_fill_blue"
								@click="toFile"
							> <label style="font-size: 16px; vertical-align: unset">{{ $language.shorts.btn_upload }} <!-- 동영상 업로드 --><input v-show="false" type="file" accept="video/*" @change="setFile('video', $event)" ref="file_video" id="file_video" /> </label></button>
						</div>
					</div>
				</template>
			</Empty>
		</div>
	</div>
</template>

<script>
import Empty from "@/view/Layout/Empty";

export default {
	name: 'MafiaTvItem'
	, components: { Empty}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'MafiaTvItem'
				, title: this.$language.tv.title_tv_item
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
			}
			, file_max: 1
			, files: []
			, upload_files: []
			, post_file_list: [
				{}
				,{}
			]
			, item_cover: {
				src:''
			}
			, item_board_config: {

			}
			, is_show: false
			, mode: 'empty'
			, post_title: ''
			, post_contents: ''
			, appendix_file_div_code: 'CA01000001'
			, file_upload_code: 'CM00700015'
			, stremg_video_file_list: []
			, item_aws: {

			}
			, item_video: {

			}
			, item: {

			}
			, is_cover: false
			, parents_post_number: this.$route.params.p_id ? this.$route.params.p_id : ''
			, post_level: this.$route.params.p_id ? 2 : 0
			, catgy_code: this.$route.params.p_id ? 'CA04300002' : 'CA04300003'  //CA04300001:전체, CA04300002:시리즈/에피소드, CA04300003:단편, 시리즈는 부모게시번호 필요
		}
	}
	, methods: {
		setFile: function (type) {

			let file_count = this.files.length + 1
			if (file_count > this.file_max) {
				this.$bus.$emit('notify', {type: 'error', message: this.$language.common.error_file_limit})
				return false
			}

			let post_appendix_file_div_code = ''

			this.item_video = this.$refs.file_video.files[0]
			let file = this.item_video;

			const videourl = URL.createObjectURL(file);

			if (type.indexOf('video') > -1) {
				post_appendix_file_div_code = 'CA01000002'
			} else if (type.indexOf('audio') > -1) {
				post_appendix_file_div_code = 'CA01000003'
			} else {
				post_appendix_file_div_code = 'CA01000001'
			}
			this.upload_files.unshift({type: type, file: file, src: videourl})
			this.files.unshift({type: post_appendix_file_div_code, src: videourl})
			this.stremg_video_file_list[0] = {
				stremg_video_file_indexnumber: 2
				, stremg_video_file_name: file.name
			}

			this.post_file_list[1] = {
				post_appendix_file_div_code: post_appendix_file_div_code
				, post_file_url: file.name
				, post_appendix_file_indexnumber: 2
				, index: file_count
			}

			console.log('stremg_video_file_list', this.stremg_video_file_list)
		}
		, toFile: function () {
			this.$bus.$emit('not_pause')
		}

		, capture: function () {
			this.is_cover = true
			let canvas = document.getElementById("canvas");
			let video = document.getElementById("video");
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;
			canvas
				.getContext("2d")
				.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

			/** Code to merge image **/
			/** For instance, if I want to merge a play image on center of existing image **/
			const playImage = new Image();
			playImage.src = "path to image asset";
			playImage.onload = () => {
				const startX = video.videoWidth / 2 - playImage.width / 2;
				const startY = video.videoHeight / 2 - playImage.height / 2;
				const ctx = canvas.getContext("2d")
				ctx.filter = 'brightness(50%)'

				canvas.drawImage(playImage, startX, startY, playImage.width, playImage.height);
				canvas.toBlob = (blob) => {
					const img = new Image();
					img.src = window.URL.createObjectUrl(blob);
				};
			};
		}

		, save: function(){
			let canvas = document.getElementById("canvas");
			let file_name = this.$route.params.idx + '_' + this.$route.params.b_id + '_' + Date.now() + '.png'
			console.log(file_name)
			canvas.getContext("2d")
			canvas.filter = 'brightness(50%)'
			canvas.toBlob( (blob) => {
				this.mode = 'empty'
				let file = new File([blob], file_name, { type: 'image/png' });
				console.log(file)
				this.postFile(file)
			})
		}
		, goBack: function(){
			if(this.mode == 'edit'){
				this.mode = 'empty'
			}else{
				if(this.upload_files.length > 0){
					this.upload_files = []
					this.files = []
					this.post_file_list = {}
				}else{
					this.$emit('goBack')
				}
			}
		}
		, getBoardConfig: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
					}
					, type: true
				})

				if (result.success) {
					this.item_board_config = result.data
				} else {
					if(result.code == 'E001201021'){
						this.$bus.$emit('notify', {type: 'error', message: result.result_msg})
					}
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, toEdit: function(){
			this.mode = 'edit'
		}
		, postSave: async function(){
			if(!this.post_contents){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.shorts.error_shorts_contents})
				return false
			}
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_tv
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, parents_post_number: this.parents_post_number
						, post_level: this.post_level
						, post_title: this.post_title
						, post_contents: this.post_contents
						, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
						, post_file_list: this.post_file_list
						, catgy_code: this.catgy_code
						, vote_fg: 'N'
						, vote_info: {}
						, vote_list: []
						, comment_writing_fg: 'Y'
					}
					, type: true
				})

				if (result.success) {
					console.log(result.data)
					this.item_aws = result.data
					await this.postAws()
				} else {
					if(result.code == 'E001201021'){
						this.$bus.$emit('notify', {type: 'error', message: result.result_msg})
					}
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}

		, postFile: async function(file){
			try{
				this.$bus.$emit('on', true)
				let login_info = this.$encodeStorage.getSessionAt()

				let domain = process.env.VUE_APP_DOMAIN
				let domain_m = process.env.VUE_APP_DOMAIN_M
				let domain_stg = process.env.VUE_APP_DOMAIN_STG
				let domain_stgm = process.env.VUE_APP_DOMAIN_STG_M
				let domain_dev = process.env.VUE_APP_DOMAIN_DEV
				let domain_devm = process.env.VUE_APP_DOMAIN_DEV_M
				let dev = process.env.VUE_APP_DEV
				let server = process.env.VUE_APP_SERVER
				let stg = process.env.VUE_APP_STG
				let local = process.env.VUE_APP_LOCAL
				let location = window.location.href

				let baseUrl = ''

// 운영계 API 호출
				if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
					baseUrl = server

// 검증계 API 호출
				}else if(location.indexOf(domain_stgm) > -1 || location.indexOf(domain_stg) > -1){
					baseUrl = stg

// 개발계 API 호출
				}else if(location.indexOf(domain_dev) > -1 || location.indexOf(domain_devm) > -1){
					baseUrl = dev

// 로컬 API 호출
				}else{
					baseUrl = local
				}

				let data = new FormData();

				data.append('member_number', login_info.member_number)
				data.append('file_upload_code', this.file_upload_code)
				data.append('appendix_file_div_code', this.appendix_file_div_code)
				data.append('appendix_file_name',  file)

				const options = {
					method: 'POST'
					, body: data
					, headers: {
						'Accept': 'application/json',
						'A': this.$encodeStorage.getSessionToken(),
						'V': '1.0.0'
						, 'Content-Security-Policy' : 'upgrade-insecure-requests'
					}
				};

				let url = baseUrl + '/' + this.$api_url.api_path.post_file
				await fetch(url, options).then(response => response.json()).then( (result) => {
					console.log(result.d)
					console.log(result.resultinfo)
					if(result.resultinfo.result_div_cd == 'OK'){

						this.post_file_list[0] = {
							post_appendix_file_div_code: this.appendix_file_div_code
							, post_appendix_file_indexnumber: 1
							, post_file_url: result.d.file_url
						}
					}else{
						throw result.resultinfo.result_msg
					}
				}).catch( (e) => {
					console.log(e)
					throw e
				});

			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
				this.is_cover = false
			}
		}
		, postAws: async function(){
			try{
				this.$bus.$emit('on', true)
				let file = this.item_video;
				console.log('file', file)
				let item = this.item_aws
				const result = await this.$Request({
					method: 'put'
					, url: item.stremg_video_file_list[0].stremg_video_file_url
					, header: {
						// 올리고자하는 동영상의 확장자로 설정 - test-video 파일의 경우 mp4
						"Content-Type": "video/" + this.stremg_video_file_list[0].contents_meta_data.contents_type,
						// "SHTP-02-001 숏플 게시물 등록 요청" - stremg_video_upload_header {key:value} 로 리퀘스트 헤더에 추가
						"x-amz-tagging": item.stremg_video_file_list[0].stremg_video_upload_header['X-Amz-Tagging'][0]
						, "amz-sdk-request" : ["attempt=1; max=3"]
					}
					, data: file
					, type: true
					, is_data: true
					, isResult: true
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: this.$language.shorts.txt_tv_success})
					this.$bus.$emit('to', { name: 'MafiaTvUpload', params: { idx: this.$route.params.idx, code: this.$route.params.b_code, b_id: this.$route.params.b_id}})
				}else{
					throw result.message
				}
			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.bbs_id
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onLoadMetadata: function(e){
			console.log('onLoadMetadata', e.target.videoWidth, e.target.videoHeight, e)
			let width = e.target.videoWidth
			let height = e.target.videoHeight
			let duration = e.target.duration
			let name = this.stremg_video_file_list[0].stremg_video_file_name
			let d = name.split('.')
			let t = d[d.length - 1]
			let data = {
				contents_type: t // "콘텐츠타입", ->required   //png, mp4, mp3 등(현재는 미사용)
				, contents_original_resolu_width: width // ": 콘텐츠원본해상도너비, ->required   //동영상 또는 이미지의 너비(정수), 회전이 없는 컨텐츠일 경우 0
				, contents_original_resolu_height: height // ": 콘텐츠원본해상도높이, ->required   //동영상 또는 이미지의 높이(정수), 회전이 없는 컨텐츠일 경우 0
				, contents_original_rolling_direction: height > width ? 9 : 0 // ": 콘텐츠원본회전방향, ->required   //0(회전이 없는 컨텐츠), 1(회전 없음), 3(180도 회전), 6(시계 방향으로 90도 회전), 8(반시계 방향으로 90도 회전)
				, contents_length_seconds: duration + ''// ": 콘텐츠길이초 ->required
			}
			this.$set(this.stremg_video_file_list[0], 'contents_meta_data', data)
			this.$set(this.post_file_list[1], 'contents_meta_data', data)

			setTimeout( () => {
				this.capture()
				setTimeout( () => {
					this.save()
				}, 100)
			}, 300)

			console.log('onLoadMetadata', this.stremg_video_file_list)
		}
		, pausePlay: function(){
			let d = this.$refs.video
			console.log(d.paused)
			if(d.paused){
				d.play()
				this.$set(this.upload_files[0], 'paused', false)
			}else{
				d.pause()
				this.$set(this.upload_files[0], 'paused', true)
			}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getBoardConfig()
		if(this.$route.params.bbs_id){
			this.getData()
		}
	}

	, watch: {
	}

}
</script>
<style scoped>
textarea{border: 1px solid #dadada;}
textarea::placeholder{color: #bbb;}
</style>